.buy {
  position: fixed;
  top: 4.5em;
  z-index: 999;
  right: 0;
  height: 6.5em;

  &-label {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
    background: #fff;
    padding: 1em .4em 1em .6em;
    border-top-left-radius: .85em;
    border-bottom-left-radius: .85em;
    transform: translate3d(0, 0, 0);
    transition: transform .35s ease;

    svg {
      height: 100%;
    }

    &--second {
      transform: translate3d(100%, 0, 0);
      z-index: 2;
    }
  }

  &--scrolled {
    .buy-label--first {
      transform: translate3d(100%, 0, 0);
    }

    .buy-label--second {
      transform: translate3d(0, 0, 0);
    }
  }

  &:hover {
    .buy-label--first {
      transform: translate3d(0, 0, 0);
    }

    .buy-label--second {
      transform: translate3d(100%, 0, 0);
    }
  }
}