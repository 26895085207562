.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  background: #fff;
  transform: translate(-50%,-50%);
  padding: $base-padding/2;

  @include md {
    width: 75%;
    height: auto;
    padding: $base-padding/1.5;
  }

  @include lg {
    width: 50%;
    height: auto;
  }

  &-container {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    transform: translate3d(0,0,0);
    opacity: 0;
    display: none;
    // pointer-events: none;
  }

  &-backdrop {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: $orange;
    opacity: .9;
  }

  &-title {
    @include smmax {
      padding-right: 2rem;
    }
  }

  &-form {
    @include md {
      padding: 1rem $base-padding*1.25;
    }

    .input {
      margin-bottom: 1em;
    }

    p, .input {
      @include smmax {
        font-size: 1em;
      }
    }

    &-done {
      display: none;
    }

    &--success &-inside {
      display: none;
    }

    &--success &-done {
      display: block;
    }
  }


  &-end {
    padding-top: $base-padding/1.5;

    p {
      margin: 2rem 0 0;
      font-size: .75rem;
    }
  }

  &-close {
    position: absolute;
    top: $base-padding/4;
    right: $base-padding/3;
    width: $base-padding;
    height: 2.5rem;
    z-index: 99;
    cursor: pointer;

    @include md {
      width: 2rem;
      right: $base-padding/1.5;
      top: $base-padding/2;
    }

    &:before, &:after, {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      width: 100%;
      background: #000;
      transform: translate3d(0,0,0);
      transition: all .25s ease;
    }

    &:before {
      transform: translate3d(0,0,0) rotate(45deg)
    }
    &:after {
      transform: translate3d(0,0,0) rotate(-45deg)
    }
  }
}
