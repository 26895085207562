%arrow {
  position: relative;
  vertical-align: middle;

  &:before, &:after {
    content: '';
    position: absolute;
    display: block;
  }

  &:before {
    border-top: 1px solid currentColor;
    border-left: 1px solid currentColor;
    width: .45em;
    height: .45em;
    top: 50%;
  }

  &:after {
    width: .8em;
    height: 1px;
    top: 50%;
    left: 0;
    background: currentColor;
    left: .1em;
  }
}

%arrow-right {
  @extend %arrow;

  &:before {
    right: .1em;
    transform: translateY(calc(-50% + .5px)) rotate(135deg);
  }

}

%arrow-left {
  @extend %arrow;

  &:before {
    left: .1em;
    transform: translateY(calc(-50% + .5px)) rotate(-45deg);
  }
}

%arrow-down {
  @extend %arrow;

  &:before {
    top: 55%;
    right: .15em;
    transform: translateY(calc(-50% + .5px)) translateX(-0.5px) rotate(-135deg);
  }

  &:after {
    right: .4em;
    height: .8em;
    top: 50%;
    width: 1px;
    left: auto;
    transform: translateY(-50%);
  }
}
%arrow-up {
  @extend %arrow;

  &:before {
    top: 35%;
    right: .15em;
    transform: translateY(calc(-50% + .5px)) translateX(-1px) rotate(45deg);
  }

  &:after {
    right: .4em;
    height: .8em;
    top: 50%;
    width: 1px;
    left: auto;
    transform: translateY(-50%);
  }
}

.arrow {
  @extend %arrow;
  width: 1em;
  height: 1em;
  display: inline-block;
  position: relative;
  top: -.05em;

  &--right {
    @extend %arrow-right;
    margin-left: .15em;
  }
  &--left {
    @extend %arrow-left;
    margin-right: .15em;
  }
}
