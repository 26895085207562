.grid {
  display: flex;
  -js-display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-start;
  flex: 0 1 auto;
  flex-wrap: wrap;
  position: relative;
  z-index: 1;
  // max-width: 65rem;
  margin: 0 auto;



  @include md {
    padding: 0 $base-padding/2;
  }

  & & {
    padding: 0;
  }
}

[class*='col-'] {
  flex: 0 0 auto;
  padding: 0 $base-padding/2;
}

@for $i from 1 through 24 {
  .col-#{$i}-24 {
    flex-basis: percentage((1 / 24) * $i);
    max-width: percentage((1 / 24) * $i);
    width: percentage((1 / 24) * $i);
  }
}
@for $i from 1 through 24 {
  @include sm {
    .col-sm-#{$i}-24 {
      flex-basis: percentage((1 / 24) * $i);
      max-width: percentage((1 / 24) * $i);
      width: percentage((1 / 24) * $i);
    }
  }
}
@for $i from 1 through 24 {
  @include md {
    .col-md-#{$i}-24 {
      flex-basis: percentage((1 / 24) * $i);
      max-width: percentage((1 / 24) * $i);
      width: percentage((1 / 24) * $i);
    }
  }
}
@for $i from 1 through 24 {
  @include lg {
    .col-lg-#{$i}-24 {
      flex-basis: percentage((1 / 24) * $i);
      max-width: percentage((1 / 24) * $i);
    }
  }
}
@for $i from 1 through 24 {
  @include xl {
    .col-xl-#{$i}-24 {
      flex-basis: percentage((1 / 24) * $i);
      max-width: percentage((1 / 24) * $i);
    }
  }
}

.col-nop {
  padding: 0;
}

.col-vert {
  padding-top: $base-padding/2;
  padding-bottom: $base-padding/2;
}
