.form {
  overflow: hidden;
}

.input {
  flex-grow: 1;
  border: 0;
  border-bottom: 1px solid currentColor;
  color: currentColor;
  margin-right: $base-padding/4;
  align-items: center;
  font-family: $base-font-family;
  font-size: 1em;
  height: 2.2rem;
  line-height: 1.6rem;
  padding: 0;
  outline: 0;
  background: transparent;
  letter-spacing: 0.04em;
  width: 100%;

  &::-webkit-input-placeholder {
    color: currentColor;
  }
  &::-moz-placeholder {
    color: currentColor;
  }
  &:-ms-input-placeholder {
    color: currentColor;
  }
  &:-moz-placeholder {
    color: currentColor;
  }

  &-hidden {
    position: absolute;
    top: -9999px;
    left: -9999px;
  }
}

.form-radio {
  position: relative;
  font-size: .9rem;
  padding-left: 1.25em;
  padding-right: 1em;

  input {
    visibility: hidden;
    position: absolute;
    top: 0;
    left: 0;
  }

  span {
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      width: 14px;
      height: 14px;
      border: 1px solid currentColor;
      border-radius: 50%;
      top: 50%;
      margin-top: -7px;
    }

    &:after {
      content: '';
      position: absolute;
      left: 3px;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: currentColor;
      top: 50%;
      margin-top: -4px;
      opacity: 0;
    }
  }

  input:checked + span:after {
    opacity: 1;
  }
}

label {
  @extend p;
}

.label {
  display: block;
  margin-top: .9em;
  margin-bottom: .6em;
}

// newsletter
.newsletter-form {
  display: flex;
  align-items: flex-start;
  line-height: 1.4em;
  overflow: visible;

  .btn {
    flex-grow: 0;
    flex-shrink: 0;
  }
}
