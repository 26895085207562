.footer {
  position: relative;
  z-index: 99;
  background: #000;
  color: #fff;
  width: 100%;
  font-size: .9em;
  padding: $base-padding 0;

  @include md {
    font-size: .75rem;
    padding: $base-padding 0 $base-padding*2;
  }

  &-grid {
    justify-content: center;

    @include md {
      padding: 0;
      justify-content: space-between;
    }
  }

  &-col {
    @include smmax {
      margin-bottom: $base-padding;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  p {
    font-size: 1em !important;
  }

  li a:not(.btn), p a:not(.btn) {
    background-image: none;

    &:hover {
      @include underline(currentColor, .1em, 100%);
    }
  }

  .btn {
    span {
      background: #000;
      border-color: #fff;

      &:before {

      }
    }

    &:before {
      background: #fff;
    }
  }

  &-logo {
    @include ratio-box(1/1);
    background: url(../images/ycc-circle.svg) center / auto 100% no-repeat;
    max-width: 18em;

    @include smmax {
      margin-bottom: $base-padding;
    }

    @include md {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  &-nav {

    @include smmax {
      display: flex;

      ul {
        width: 50%;
      }
    }

    @include md {
      width: 50%;

      &-container {
        display: flex;
      }
    }
  }

}
