.btn {
  appearance: none;
  position: relative;
  display: inline-block;
  margin: 0 .75em .5em 0;
  // transform: translate3d(0,0,1px);
  font-size: .755rem;
  background: none;
  border: 0;
  line-height: 1.3rem;
  font-family: inherit;
  padding: 0;
  outline: 0;

  span {
    position: relative;
    display: inline-block;
    border: 1px solid #000;
    background: #fff;
    text-transform: uppercase;
    letter-spacing: .05em;
    padding: .5em 2.275em .5em 1em;
    transform: translate3d(0,0,0) rotate(.001deg);
    transition: transform .125s ease;
    -webkit-mask-image: -webkit-radial-gradient(white, black);

    &:after {
      content: '';
      position: absolute;
      right: 1em;
      top: 50%;
      display: inline-block;
      width: .75em;
      height: 1px;
      background: currentColor;
      vertical-align: middle;
      margin-left: .5em;
      transform: translate3d(0,0,0);
    }

    &:before {
      content: '';
      position: absolute;
      right: 1em;
      top: 50%;
      display: inline-block;
      width: 7px;
      height: 7px;
      border: 1px solid currentColor;
      border-width: 1px 1px 0 0;
      vertical-align: middle;
      transform: rotate(45deg) translate3d(0,0,0);
      margin-top: -3px;
    }
  }

  &:before {
    content: '';
    position: absolute;
    left: .325em;
    top: .325em;
    width: 100%;
    height: 100%;
    background: #000;
    transform: translate3d(0,0,0);
  }

  &[href*="//"]:not([href*="youngcollectorscircle"]) span {

    &:after {
      transform: translate3d(.25px,.25px,0) rotate(-45deg);
    }

    &:before {
      transform: translate3d(0,0,0) rotate(0deg);
      right: 1.05em;
      margin-top: -4px;
    }
  }

  &:hover span {
    transform: translate3d(-2px,-2px,0) rotate(.001deg);
  }

  // &:focus span,
  &:active span {
    transform: translate3d(.2em,.2em,0) rotate(.001deg);
  }

  &--inline {
    border-bottom: 1px solid #000;
    line-height: 1.2em;
    font-size: 1em;

    &:hover {
      border-bottom: 0;
    }

    &:before {
      display: none;
    }

    span {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      border: 0;
      text-transform: none;
      margin-right: -.85em;
      margin-bottom: -.1em;
      background: none;
    }

    &:hover span {
      transform: translate3d(0,0,0);
    }

    // &:focus span,
    &:active span {
      transform: translate3d(0,0,0);
    }
  }
}
