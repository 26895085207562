.bg-fill {
  content: '';
  position: fixed;
  top: 0;
  width: 100%;
  height: 150vh;
  right: $base-padding/-2;
  transform: translateY(-25vh);
}

.bg {
  @at-root .theme--green &-fill { background: $green; }
  @at-root .theme--blue &-fill { background: $blue }
  @at-root .theme--pink &-fill { background: $pink }
  @at-root .theme--yellow &-fill { background: $yellow }
  @at-root .theme--green &-fill { background: $green }
  @at-root .theme--orange &-fill { background: $orange }
  @at-root .theme--brown &-fill { background: $brown }
  @at-root .theme--graphite &-fill { background: $graphite }
  @at-root .theme--grey &-fill { background: $grey }
  @at-root .theme--lightblue &-fill { background: $lightblue }
  @at-root .theme--purple &-fill { background: $purple }
}

.theme {
  &--green {
    ::-moz-selection { background: $green }
    ::selection { background: $green }
  }
  &--blue {
    ::-moz-selection { background: $blue }
    ::selection { background: $blue }
  }
  &--pink {
    ::-moz-selection { background: $pink }
    ::selection { background: $pink }
  }
  &--yellow {
    ::-moz-selection { background: $yellow }
    ::selection { background: $yellow }
  }
  &--green {
    ::-moz-selection { background: $green }
    ::selection { background: $green }
  }
  &--orange {
    ::-moz-selection { background: $orange }
    ::selection { background: $orange }
  }
  &--brown {
    ::-moz-selection { background: $brown }
    ::selection { background: $brown }
  }
  &--graphite {
    ::-moz-selection { background: $graphite }
    ::selection { background: $graphite }
  }
  &--grey {
    ::-moz-selection { background: $grey }
    ::selection { background: $grey }
  }
  &--lightblue {
    ::-moz-selection { background: $lightblue }
    ::selection { background: $lightblue }
  }
  &--purple {
    ::-moz-selection { background: $purple }
    ::selection { background: $purple }
  }
}

.fill {
  &--green {
    background: $green;;
    box-shadow: $base-padding/2 0 $green, $base-padding/-2 0 $green;
  }
  &--blue {
    background: $blue;
    box-shadow: $base-padding/2 0 $blue, $base-padding/-2 0 $blue;
  }
  &--pink {
    background: $pink;
    box-shadow: $base-padding/2 0 $pink, $base-padding/-2 0 $pink;
  }
  &--yellow {
    background: $yellow;
    box-shadow: $base-padding/2 0 $yellow, $base-padding/-2 0 $yellow;
  }
  &--green {
    background: $green;
    box-shadow: $base-padding/2 0 $green, $base-padding/-2 0 $green;
  }
  &--orange {
    background: $orange;
    box-shadow: $base-padding/2 0 $orange, $base-padding/-2 0 $orange;
  }
  &--brown {
    background: $brown;
    box-shadow: $base-padding/2 0 $brown, $base-padding/-2 0 $brown;
  }
  &--graphite {
    background: $graphite;
    box-shadow: $base-padding/2 0 $graphite, $base-padding/-2 0 $graphite;
  }
  &--grey {
    background: $grey;
    box-shadow: $base-padding/2 0 $grey, $base-padding/-2 0 $grey;
  }
  &--lightblue {
    background: $lightblue;
    box-shadow: $base-padding/2 0 $lightblue, $base-padding/-2 0 $lightblue;
  }
  &--purple {
    background: $purple;
    box-shadow: $base-padding/2 0 $purple, $base-padding/-2 0 $purple;
  }
}
