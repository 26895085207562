.newsletter-popup {
  position: fixed;
  z-index: 999;
  bottom: $base-padding/2;
  right: $base-padding/2;
  width: calc(50% - #{$base-padding});
  padding: $base-padding;
  background: #fff;
  box-shadow: 1px 2px 5px 0 rgba(0, 0, 0, 0.2);
  display: none;
  opacity: 0;
  font-size: .8rem;

  &-close {
    position: absolute;
    top: $base-padding/1.35;
    right: $base-padding;
    width: $base-padding*.75;
    height: 2.5rem;
    z-index: 99;
    cursor: pointer;

    &:before, &:after, {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      width: 100%;
      background: #000;
      transform: translate3d(0,0,0);
      transition: all .25s ease;
    }

    &:before { transform: translate3d(0,0,0) rotate(45deg) }
    &:after { transform: translate3d(0,0,0) rotate(-45deg) }
  }

  @include smmax {
    display: none !important;
  }
}

.newsletter-bar {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  width: 100%;
  background: #000;
  color: #fff;
  text-align: center;
  padding: .75em 1em;
  transform: translate3d(0,100%,0);
  transition: transform .4s ease;

  &--show {
    transform: translate3d(0,0,0);
  }

  span {
    display: inline-block;
    padding-right: 1.5em;
    transform: translate3d(0,0,0) rotate(.001deg);
    @extend %arrow-down;

    &:before {
      right: .15em;
      transform: translateY(calc(-50% + 1px)) translateX(-1.5px) rotate(-135deg);
    }
  }

  @include md { display: none }
}
