.article {
  @extend .page;
  padding-bottom: 0;

  &-meta {
    margin: 0 0 $base-padding/2;
    letter-spacing: .02em;
  }

  &-footer {

    &-inside {
      margin-top: $base-padding/2;
      padding-bottom: $base-padding/2;
      display: flex;
      -js-display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &-inside:before {
      content: '';
      display: block;
      width: 100%;
      border-top: 1px solid #000;
    }

    &-link {
      display: block;
      padding-top: $base-padding/2;
      letter-spacing: 0.02em;
      font-size: .95rem;

      &:hover {
        @include underline(currentColor, .125em, 100%);
      }

      @include smmax {
        span { display: none }

        &--next {
          &:before {
            content: 'Volgende';
            text-transform: uppercase;
          }
        }
        &--prev {
          &:after {
            content: 'Vorige';
            text-transform: uppercase;
          }
        }
      }
    }
  }
}
