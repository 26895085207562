.embed {
  position: relative;
  display: block;
  
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
  }
  
  &__thumb { display: none }
}