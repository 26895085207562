.page {
  padding-top: $base-padding / 8;
  padding-bottom: $base-padding / 2;

  .intro, &-images, &-embed {
    @include md {
      width: percentage(1 / ((1/24) * 12));
      margin-left: percentage(((1/24) * 23.25) / -1);
      padding: 0 $base-padding/2;
    }
    @include lg {
      width: percentage(1 / ((1/24) * 15));
      margin-left: percentage(((1/24) * 15) / -1);
    }
  }

  h1, h2, h5 {
    @include md {
      width: percentage(((1/24) * 24));
      margin-left: calc(#{percentage(((1/24) * 20) / -2)} + #{$base-padding/4});
    }
    @include lg {
      width: percentage(((1/24) * 19));
      margin-left: calc(#{percentage(((1/24) * 19) / -4)} - #{$base-padding/4});
    }
  }

  h2:last-child {
    margin-bottom: 0;
  }

  &-content {
    padding-bottom: 2.1rem;

    &--share {
      // margin-top: -2.1rem;
      padding-bottom: 0;
    }

    p, ol, ul, h4 {
      @include md {
        padding: 0 $base-padding/2;
      }
      @include lg {
        padding: 0 $base-padding/1.5;
      }
    }

    ul li {
      position: relative;
      padding-left: 1.75em;

      @extend %arrow-right;

      &:before, &:after {
        top: .7em;
      }

      &:before {
        left: .4em;
      }
    }

    blockquote {
      padding: $base-padding/2 $base-padding/2 $base-padding;
      text-align: center;

      @include md {
        position: absolute;
        left: 0;
        width: 45%;
        padding: 0 $base-padding/2;
      }

      @include lg {
        width: 40%;
      }

      p {
        max-width: 20rem;
        margin: 0 auto;
        font-family: $title-font-family;
        font-size: 1.1rem;
        line-height: 1.3em;
      }

      em {
        font-family: $base-font-family;
        display: block;
        font-size: .75rem;
        font-style: normal;
        text-transform: uppercase;
        line-height: 1.3em;

        &:before {
          content: '○';
          display: block;
          margin: .5em;
        }
      }
    }
  }

  &-images {
    display: flex;
    -js-display: flex;
    padding: 0;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: $base-padding/2;

    &-image {
      flex-grow: 1;
      flex-basis: 100%;
      min-width: 100%;
      padding: $base-padding/4 0;

      @include md {
        flex-basis: 33.333%;
        min-width: 33.3333%;
        padding: $base-padding/3 $base-padding/2;
        max-width: 62vw;
      }

      img {
        display: block;
        max-width: 100%;
        height: auto;
        margin: 0 auto;
      }

      figcaption {
        padding-top: $base-padding / 4;

        h3 {
          padding-top: .5rem;
          margin-bottom: 0;
        }

        @include smmax {
          max-width: 66vw;
          margin-left: auto;
        }

        @include md {
          max-width: calc(62vw - #{$base-padding * 3.25});
        }
      }
    }

    &--three {
      justify-content: flex-start;

      img {
        width: 100%;
      }
    }

    &--three &-image {
      @include md {
        max-width: 33.333%;
      }
    }

    &-btn {
      width: 100%;
      flex-basis: 100%;
      text-align: center;
      padding: $base-padding/3 $base-padding/2;
    }

    &--full &-image {
      width: 100%;
      max-width: none;
      padding-bottom: $base-padding/2;

      figcaption {
        margin-left: auto;
      }


      img {
        width: auto;
        max-height: 100vh;
      }
    }
  }
  
  &-embed {
    padding-bottom: $base-padding;
  }
}
