// colors
$blue: #c9e7f5;
$pink: #fbdfe0;
$yellow: #fffed9;
$green: #e7ffec;
$orange: #ffe7dd;
$brown: #ddcdb0;
$graphite: #cfdbdc;
$grey: #e7e7e7;
$lightblue: #ecffff;
$purple: #ebe3eb;

// base
$base-color: #fff;
$base-selection-color: #ffa77c;
$base-font-color: #000;
$base-font-family: 'suisseweb', Arial, sans-serif;
$title-font-family: 'noe', Georgia, serif;
$base-font-size: 20px;
$base-padding: 2.5rem;
$max-width: 60rem;
$small-font-size: .75em;

// sizes
$size-xs: 568px;
$size-md: 768px;
$size-lg: 1280px;
$size-xl: 1500px;
