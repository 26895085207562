/*

 ••• •••
 ••• •••

 ••• ••• •••  ••  ••
 ••• ••• ••• ••• •••
 ••• ••• ••• ••• ••• •••
 ••• ••• ••• ••• ••• •••
 ••

 -- www.vanjim.nl

*/

// utilities
@import 'utilities/utilities';

// vendor
@import 'vendor/swiper';

// base
@import 'base/settings';
@import 'base/reset';
@import 'base/global';
@import 'base/layout';
@import 'base/grid';
@import 'base/responsive';
@import 'base/typography';
@import 'base/animation';

// components
@import 'components/color';
@import 'components/header';
@import 'components/footer';
@import 'components/button';
@import 'components/form';
@import 'components/arrows';
@import 'components/cookies';

// sections
@import 'sections/home';
@import 'sections/page';
// @import 'sections/articles';
@import 'sections/article';
@import 'sections/event';
@import 'sections/modal';
@import 'sections/embed';
@import 'sections/buy';
@import 'sections/newsletter';