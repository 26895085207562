html, body {
  font: $base-font-size*.8 $base-font-family;
  color: $base-font-color;
  @include smmax {
    overflow-x: hidden;
  }
  @include sm {
    font-size: $base-font-size * .9;
  }
  @include md {
    font-size: $base-font-size;
  }
}

body {
  padding-top: 4rem;
}

iframe {
  // background: $yellow;
}
