.home {
  font-size: .9rem;

  @include smmax {
    padding: 0;
  }

  @include md {
    p {
      font-size: .8rem;
    }
  }

  &-hero {
    // position: relative;
    z-index: 1;
    margin-top: -4rem;
    // width: 100%;

    @include smmax {
      padding-top: calc( 4rem + #{$base-padding});
      padding-bottom: $base-padding;
      background: #fff;
    }

    &-item {
      position: relative;
      padding-top: calc( 4rem + #{$base-padding});
      padding-bottom: $base-padding;

      @include md {
        @media(max-height: 728px) {
          padding-top: calc( 4rem + #{$base-padding/2});
        }

        @media(max-height: 675px) {
          padding-top: calc( 4rem + #{$base-padding/4});
          padding-bottom: $base-padding*.75;
        }
      }

      @include lg {
        padding-top: calc( 4rem + #{$base-padding});
        padding-bottom: $base-padding;

        @media(max-height: 728px) {
          padding-top: calc( 4rem + #{$base-padding/2});
        }

        @media(max-height: 675px) {
          padding-top: calc( 4rem + #{$base-padding/4});
          padding-bottom: $base-padding*.75;
        }
      }

      &-bg {

        &-grid {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          width: 100%;
        }
      }

      &-grid {
        justify-content: flex-start;

        @include smmax {
          flex-direction: column-reverse;
        }
      }

      &-heading {
        &-grid {
          position: relative;
          z-index: 2;
        }

        h1 {
          margin-left: auto;
          margin-right: auto;
          text-align: center;
          margin-bottom: -.525em;

          @include md {
            width: 80%;
            max-width: 15em;
            margin-bottom: -.525em;
          }
        }
      }

      &-text {
        padding-top: $base-padding/2;

        @include md {
          padding-top: $base-padding * 1.5;
          padding-right: $base-padding;
        }

        .btn + .btn {
          margin-left: 1em;
        }
      }

      &-image {

        @include md {
          margin-left: -0.3125rem;
        }

        &-wrap {
          @include ratio-box(16/9);
          position: relative;

          @include md {
            margin-left: $base-padding * -1;

            @media(max-height: 700px) {
              @include ratio-box(16/8);
            }
          }

          @include lg {
            margin-left: $base-padding * -0.5;

            @media(max-height: 750px) {
              @include ratio-box(16/8);
            }

            @media(max-height: 700px) {
              @include ratio-box(16/7);
            }

            @media(max-height: 650px) {
              @include ratio-box(16/6);
            }
          }

          img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }

    @at-root .swiper-container-horizontal > &-pagination, &-pagination, .swiper-container-horizontal > .home-news-pagination, .home-news-pagination {
      bottom: $base-padding;
      left: 0;
      width: 100%;
      padding: 0 $base-padding*0.25 0 $base-padding/2;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      @include md {
        width: percentage(1/24*11);
        padding: 0 $base-padding*0.75 0 $base-padding;
      }

      @include lg {
        width: percentage(1/24*9);
      }

      @at-root .swiper-container-horizontal > &-item, &-item {
        display: block;
        position: relative;
        width: 100%;
        margin: 0 $base-padding/4 0 0 !important;
        height: 1em;
        background: transparent;
        max-width: 5.5rem;
        border-radius: 0;
        opacity: 1;

        &:before {
          content: '';
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #e7e7e7;

          @include smmax {
            box-shadow: 0 -.5px 0 #e7e7e7, 0 .5px 0 #e7e7e7;
          }
        }

        span {
          position: absolute;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 2px;
          background: #000;
          transform-origin: right;
          transform: scaleX(0);
          transition: transform .4s ease;
        }

        &.swiper-pagination-bullet-active span {
          transition: transform .05s ease;
          transform-origin: left;
        }
      }
    }
  }

  &-news {
    position: relative;
    z-index: 2;
    background: $grey;
    padding: $base-padding*.75 0 $base-padding*.7;
    box-shadow: 0 2px 5px -3px rgba(0, 0, 0, 0.5);

    @include md {
      display: flex;
      align-items: center;
    }

    &-title {
      flex-shrink: 0;
      flex-grow: 1;
      padding: $base-padding/2;

      h3 {
        display: inline-block;
        margin: 0;
      }

      @include md {
        padding: 0 $base-padding/2;
      }

    }

    &-items {
      padding: 0 0 $base-padding;

      @include md {
        font-size: .8rem;
        padding: 0 $base-padding/4;
      }

      &-item {
        width: 100%;
        padding: $base-padding/6 $base-padding/2;

        @include md {
          border-right: 1px solid #000;
          padding: $base-padding/6 $base-padding/1.5;

          &:last-of-type {
            border-right: 0;
          }
        }

        p {
          @include md {
            // margin: 0 0 -.2em;
          }
        }

        .btn {
          font-size: .975em;
        }

        @include md {
          width:  30em;
        }
      }
    }

    &-pagination {
      position: absolute;
      bottom: $base-padding/2 !important;
      left: 0;

      &-item {
        &:before {
          background: #fff;

          @include smmax {
            box-shadow: 0 -.5px 0 #fff, 0 .5px 0 #fff;
          }
        }
      }

      @include md {
        display: none !important;
      }
    }

    &-button {
      color: #000;
      top: 0;
      height: 100%;
      margin-top: 0;
      width: $base-padding * 1.5;
      transition: opacity .35s ease;

      @include smmax {
        display: none !important;
      }

      &--prev {
        left: 0;
        background: linear-gradient(to left, rgba($grey,0) , rgba($grey,1) 50%);
        padding-right: $base-padding/2;
      }

      &--next {
        right: 0;
        background: linear-gradient(to right, rgba($grey,0), rgba($grey,1) 50%);
        padding-left: $base-padding/2;
      }

      &.swiper-button-disabled {
        opacity: 0;
      }

      &:after {
        font-size: 1em;
      }
    }
  }
}
