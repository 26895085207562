.cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 9999;
  background: #000;
  padding: $base-padding/2;
  color: #fff;
  font-size: .85rem;
  max-width: 100%;
  display: none;
  opacity: 0;
  transition: opacity .4s ease, transform .4s ease;
  transform: translate3d(0,2rem,0);

  @include md {
    p { font-size: .7rem !important }
  }

  @include md {
    width: 30%;
    max-width: 26em;
    bottom: 0;
    right: $base-padding/2;
  }

  @supports (pointer-events: none) {
    pointer-events: none;
    display: block;
  }

  &--show {
    display: block;
    opacity: 1;
    transform: translate3d(0,0,0);
    pointer-events: all;
  }

  [data-text] {
    &:after {
      content: attr(data-text);
    }
  }

  &-consent {
    &-text {
      display: block;
      line-height: 1.4em;
      padding-right: 1em;
    }

    &-list {
      padding: 0 0 1rem;
      line-height: 1.4em;
      font-size: 1em;

      input {
        visibility: hidden;
        position: absolute;
        opacity: 0;
      }

      &-check {
        position: relative;
        display: inline-block;
        width: 1.6rem;
        height: 1rem;
        vertical-align: middle;
        background: #595959;
        border-radius: 1em;
        margin-right: .5em;
        color: #000;
        cursor: pointer;
        transition: background-color .3s ease;

        @include smmax {
          width: 1.6rem * 1.25;
          height: 1rem * 1.25;
        }

        &:after {
          content: '';
          position: absolute;
          left: 3px;
          top: 3px;
          width: calc(1rem - 6px);
          height: calc(1rem - 6px);
          border-radius: 100%;
          background: currentColor;
          transition: transform .3s ease;

          @include smmax {
            width: calc(1.25rem - 6px);
            height: calc(1.25rem - 6px);
          }
        }
      }

      input:checked + &-check {
        background: #fff;

        &:after {
          transform: translateX(calc(.7rem - 2px));

          @include smmax {
              transform: translateX(calc(.9rem - 3px));
          }
        }
      }

      input:disabled + &-check {
        background: #fff;
        cursor: not-allowed;
        color: #000;
      }

      label {
        display: block;
        margin-bottom: .35em;
        font-size: 1em;

        &:after {
          @include smmax {
            font-size: .85em;
          }
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    &-buttons {
      display: flex;

      .btn {
        flex-shrink: 0;
      }

      .btn:first-child {
        margin-right: 1em;
      }
    }
  }

  .btn {
    font-size: .85em;

    span {
      background: #000;
      border-color: #fff;
      white-space: nowrap;

      &:before {

      }
    }

    &--primary span {
      background: #fff;
      color: #000;
      border-color: #000;
    }

    &:before {
      background: #fff;
    }
  }
}
