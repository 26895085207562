.header {
  position: fixed;
  top: 0;
  left: 50%;
  width: 100%;
  user-select: none;
  z-index: 999;
  transform: translate3d(-50%,0,0);
  height: 4rem;

  &-logo {
    position: absolute;
    top: 0;
    left: $base-padding / 2;
    font-size: 1.6rem;
    line-height: 4rem;
    z-index: 1;
    letter-spacing: 0;

    em {
    }
  }

  // &-nav {
  //   &--up .nav {
  //     transform: translate3d(0,-100%,0);
  //   }
  //
  //   &--down .nav {
  //     transform: translate3d(0,0,0);
  //   }
  // }
}

.nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  text-align: center;
  font-size: .9rem;
  transition: transform .35s ease;

  &--languages {
    display: none;

    @include md {
      display: block;
      width: auto;
      left: auto;
      right: 4.4rem;
    }

    @include lg {
      display: none;
    }
  }

  &-list {
    max-width: none;
    line-height: 3.8rem;
    font-size: .8rem;

    &-item {
      list-style: none;
      margin: 0 .3rem;
      display: inline-block;
      vertical-align: middle;

      a {
        background: none;

        &:hover {
          @include underline(currentColor, .125em, 100%);
        }
      }

      &--active a {
        @include underline(currentColor, .125em, 100%);
      }

      &--language {
        font-size: .7rem;
        text-transform: uppercase;
        margin-left: 0;
        white-space: nowrap;
        overflow: visible;

        &:first-child:after {
          position: absolute;
          margin-left: .3em;
          content: '/';
        }
      }

      &--social {
        @include hide-text;
        position: relative;
        width: 2rem;
        height: 2rem;
        background: #fff;
        border-radius: 50%;
        margin: 0;
        background-size: 100%;
        margin-left: $base-padding/6;

        @include md {
          width: 1.5rem;
          height: 1.5rem;
        }

        &__facebook { background: #fff url(../images/icon-fb.svg) center no-repeat }
        &__instagram { background: #fff url(../images/icon-instagram.svg) center no-repeat }
        &__linkedin { background: #fff url(../images/icon-linkedin.svg) center no-repeat }

        &__facebook:hover { background: #000 url(../images/icon-fb--white.svg) center no-repeat }
        &__instagram:hover { background: #000 url(../images/icon-instagram--white.svg) center no-repeat }
        &__linkedin:hover { background: #000 url(../images/icon-linkedin--white.svg) center no-repeat }

        a {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &--sub {
    width: auto;
    right: $base-padding / 2;
    text-align: right;
    left: auto;
  }

  &--sub &-list-item:last-child {
    margin-right: 0;
  }

  &--sub .nav-list-item:not(.nav-list-item--social) {
    overflow-x: hidden;
    width: 1.7em;
  }

  @include smmax {
    position: relative;
  }
}

@include mdmax {
  .header-hamburger {
    position: fixed;
    top: $base-padding/4;
    right: $base-padding/3;
    width: $base-padding;
    height: 2.5rem;
    z-index: 99;

    &:before, &:after, {
      content: '';
      position: absolute;
      top: 50%;
      left: 0;
      height: 1px;
      width: 100%;
      background: #000;
      transform: translate3d(0,0,0);
      transition: all .25s ease;
    }

    &:before {
      transform: translate3d(0,-5px,0);
    }
    &:after {
      transform: translate3d(0,5px,0);
    }

    &--open {
      span {
        opacity: 0;
        transform: translate3d(0,0,0) rotateX(90deg)
      }

      &:before { transform: translate3d(0,0,0) rotate(45deg) }
      &:after { transform: translate3d(0,0,0) rotate(-45deg) }
    }

  }

  .header-nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background: #fff;
    display: none;
    opacity: 0;

    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: percentage((1 / 24) * 4);

      @include sm {
        left: percentage((1 / 24) * 11);
      }
      margin-left: $base-padding/2;
    }

    @at-root .theme--green &:before { background: $green; }
    @at-root .theme--blue &:before { background: $blue }
    @at-root .theme--pink &:before { background: $pink }
    @at-root .theme--yellow &:before { background: $yellow }
    @at-root .theme--green &:before { background: $green }
    @at-root .theme--orange &:before { background: $orange }
    @at-root .theme--brown &:before { background: $brown }
    @at-root .theme--graphite &:before { background: $graphite }
    @at-root .theme--grey &:before { background: $grey }
    @at-root .theme--lightblue &:before { background: $lightblue }
    @at-root .theme--purple &:before { background: $purple }
  }

  .nav--main {
    position: absolute;
    top: 4rem;
    left: 1rem;
    right: 1rem;
    bottom: 1rem;
    display: flex;
    -js-display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-bottom: 1px solid #000;
    width: calc(100vw - 2rem);
    padding-bottom: 6rem;

    .nav-list-item {
      display: block;
      text-align: center;
      font-size: 2.2rem;
      line-height: 1.3em;
      letter-spacing: 0.03em;
    }
  }

  .nav--sub {
    position: absolute;
    top: auto;
    bottom: 2rem;
    left: 1rem;
    right: 1rem;
    width: calc(100vw - 2rem);
    text-align: center;

    .nav-list-item:not(.nav-list-item--social) {
      display: block;
      line-height: 1rem;
      font-size: 1.35em;
      width: auto;
      overflow: visible;
      margin-bottom: .5em;
      letter-spacing: 0.02em;

      &.nav-list-item--language {
        display: inline-block;
        margin-right: .75em;
        padding-top: .5em;
        font-size: 1em;

        @include md {
          display: none;
        }
      }
    }
  }
}
