.events {
  position: relative;
  z-index: 2;

  &-grid {
    align-items: stretch;
  }

  &-aside {
    background: #fff;

    &-inside {
      padding: $base-padding/2 0 0 0;
    }

    @include md {
      &-inside {
        position: sticky;
        display: flex;
        top: $base-padding;
        flex-direction: column;
        margin-left: $base-padding/-2;
        padding: $base-padding/2 $base-padding $base-padding/2 $base-padding/2;
        width: calc(100% + #{$base-padding});
      }

      h3 {
        margin-left: $base-padding/-2;
      }
    }
  }
}

.event {
  position: relative;
  width: calc(100% + #{$base-padding/2});
  padding: $base-padding $base-padding/2 $base-padding 0;

  &-bg {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    @include smmax {
      left: 17.75%;
    }
  }

  @include md {
    width: calc(100% + #{$base-padding*1.05});
    margin-left: $base-padding*-0.05;
    padding: $base-padding/1.5 $base-padding/2;

    &:first-of-type {
      // padding-top: $base-padding/2 + 4rem;
    }
  }

  @include lg {
    width: calc(100% + #{$base-padding*1.125});
    margin-left: $base-padding*-0.125;
    padding-left: 10.5rem;
  }

  &-image {
    @include ratio-box(16/9);
    position: relative;
    width: calc(100% - #{$base-padding});
    margin-bottom: $base-padding/2;

    @include md {
      width: 70%;
      left: $base-padding*-1;
    }

    @include lg {
      position: absolute;
      left: $base-padding*-.5;
      width: 10.5rem;
    }

    img {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &-info {
    position: relative;
    margin-bottom: 1em;
    padding-left: $base-padding*1.25;

    &-title h3 {
      margin-bottom: 0;
    }

    &-meta {
      font-size: $small-font-size;
      letter-spacing: 0.04em;

      @include smmax {
        padding-top: .5em;
      }

      &-language {
        display: inline-block;
        position: relative;
        flex-shrink: 0;
        flex-grow: 0;
        width: .9rem;
        height: .9rem;
        background: url(../images/icon-language.svg) left center;
        background-size: 100% auto;
        vertical-align: middle;
        margin-top: -.1em;

        &:after {
          content: 'English speakers welcome.';
          position: absolute;
          right: 0;
          font-size: $small-font-size;
          background: #fff;
          white-space: nowrap;
          padding: .5em 1em;
          bottom: 125%;
          display: none;
          z-index: 15;

          @include md {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        &:hover:after {
          display: block;
        }
      }
      &-info {
        display: inline-block;
        position: relative;
        flex-shrink: 0;
        flex-grow: 0;
        width: .9rem;
        height: .9rem;
        background: url(../images/icon-info.svg) left center;
        background-size: 100% auto;
        vertical-align: middle;
        margin-top: -.1em;

        &:after {
          content: attr(data-text);
          position: absolute;
          right: 0;
          font-size: $small-font-size;
          background: #fff;
          white-space: nowrap;
          padding: .5em 1em;
          bottom: 125%;
          display: none;
          z-index: 15;

          @include md {
            right: auto;
            left: 50%;
            transform: translateX(-50%);
          }
        }

        &:hover:after {
          display: block;
        }
      }
    }

    &-date {
      position: absolute;
      left: 0;
      top: 0;
      text-align: center;

      &-day, &-month {
        display: block;
        margin: 0;
        text-align: center;
        width: 2.2rem;
        margin-left: -.2rem;
      }

      &-month {
        margin-top: -.1em;
        text-transform: uppercase;
        font-size: $small-font-size;
        letter-spacing: .05em;
      }
    }
  }

  &-description {
    position: relative;

    @include md {
      padding-left: $base-padding*1.25;
    }

    &-more {
      max-height: 0;
      overflow: hidden;
      transition: max-height .35s ease;

      &-inside {
        padding-bottom: .1em;
      }
    }
  }

  &-actions {
    position: relative;
    @include md {
      padding-left: $base-padding*1.25;
    }

    &-toggle {
      display: inline-block;
      font-size: .9rem;
      letter-spacing: .04em;
      margin: .5em 1.5em;
      @include underline(#000, .15em, 100%);
      @extend %arrow-down;
      padding-right: 1em;
      line-height: 1.6em;
      transform: translate3d(0,0,0) rotate(.001deg);

      &:hover {
        background: none;
      }

      @include md {
        font-size: $small-font-size;
      }

      &--less {
        @extend %arrow-up;
      }
    }
  }

  // colors
  $colors: (
  1: #e7ffec,
  2: #ced8db,
  3: #ead6bd,
  4: #eff0ea,
  5: #e4d3cd,
  6: #ffe9c9,
  7: #d9f7f8,
  8: #d3e2d8,
  9: #ffe7dd,
  10: #eee3de,
  11: #c4d0c1,
  12: #eee2c6,
  13: #c2d7d6,
  14: #e4e2cb,
  15: #e0dee9,
  0: #fff9d8);

  @each $i, $color in $colors {
    &[data-num="#{$i}"] &-bg {
      background-color: $color;
    }
  }

}
