@font-face {
  font-family: 'suisseweb';
  src:  url('../fonts/SuisseIntl-Light-WebS.woff2') format('woff2'),
        url('../fonts/SuisseIntl-Light-WebS.woff') format('woff');
}
@font-face {
  font-family: 'noe';
  src:  url('../fonts/Noe_Display_Medium.woff2') format('woff2'),
        url('../fonts/Noe_Display_Medium.woff') format('woff');
}

// titles
h1, .h1 {
  font-size: 3.6rem;
  line-height: 1em;
  font-family: $title-font-family;
  text-align: center;
  margin: 0 0 .5em;

  @include smmax {
    font-size: 2.6rem;
  }
}

h2, .h2 {
  font-size: 2.25rem;
  line-height: 1em;
  font-family: $title-font-family;
  text-align: center;
  margin: 0 0 .75em;
}

h3, .h3 {
  font-size: 1.6rem;
  line-height: 1.1em;
  font-family: $title-font-family;
  margin: 0 0 .5em;
}

h4, .h4 {
  text-transform: uppercase;
  max-width: 30rem;
  letter-spacing: 0.02em;
}

h5, .h5 {
  font-size: $small-font-size;
  text-align: center;
}

h6 {
  text-transform: uppercase;
}

// block
p, ul, ol {
  line-height: 1.4em;
  margin-bottom: 1.4em;
  max-width: 30rem;
  letter-spacing: 0.04em;

  a {
    @include underline(currentColor, .1em, 100%);

    &:hover {
      background: none;
    }
  }
}

blockquote {

}

// inline
strong {
  font-weight: bold;
}

em {
  font-style: italic;
}

a {
  color: inherit;
  text-decoration: none;
}

small {
  font-size: $small-font-size;
}

// list

.intro {
  font-size: 1.5rem;
  letter-spacing: 0.015em;

  p {
    max-width: none;
    line-height: 1.3em;
    margin-bottom: 2em;
  }
}

figcaption { font-size: $small-font-size }
